<template>
  <div class="title-top">
    <div class="TopBox">
      <div class="return" @click="close">
        <img
          src="@/assets/image/resumeLogin/箭头.png"
          alt=""
          class="return-img"
        />
        <div class="return-text">返回</div>
      </div>
      <div class="pdfBT" @click="derive">一键导出</div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    close() {
      window.close()
    },
    derive() {
      this.$emit('derive')
    }
  }
}
</script>
<style scoped lang="stylus">
.title-top
  width 100%
  height 62px
  background #FFFFFF
  box-shadow 0px 0px 20px 0px rgba(221, 221, 221, 0.4)
  box-sizing border-box
  display flex
  justify-content center
  .TopBox
    width 1200px
    height 62px
    display flex
    justify-content space-between
    align-items center
  .pdfBT
    cursor pointer
    width 88px
    height 34px
    background #003DBB
    border-radius 4px
    font-size 16px
    font-family PingFang SC
    font-weight 500
    color #FFFFFF
    line-height 30px
    text-align center
  .return
    cursor pointer
    display flex
    align-items center
    left 610px
    margin-top 4px
    .return-img
      width 11px
      height 11px
      margin-right 6px
    .return-text
      font-size 16px
      font-family PingFang SC
      font-weight 500
      color #333333
</style>
