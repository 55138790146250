<template>
  <div :class="{ overflowHidden: token }">
    <div v-if="token" class="web-view-loading">
      <i class="el-icon-loading"></i>
      <div class="loading-text">生成中...</div>
    </div>
    <div v-if="currentResumeInfo" class="box">
      <pefTop @derive="printFn" />
      <div class="preview-Box">
        <div class="preview-left">
          <preview1 v-if="isIndex === 0" ref="preview1" />
          <preview2 v-if="isIndex === 1" ref="preview2" />
          <preview3 v-if="isIndex === 2" ref="preview3" />
          <preview4 v-if="isIndex === 3" ref="preview4" />
          <preview5 v-if="isIndex === 4" ref="preview5" />
        </div>
        <div class="preview-rigth">
          <div class="title">在线简历模板</div>
          <div class="preview-list" v-if="onlineResumeList.length">
            <div
              class="list-box"
              v-for="(item, index) in onlineResumeList"
              :key="index"
              @click="selectFn(item)"
              :class="{ actvie: index === isIndex }"
            >
              <div class="box-baseimg" v-if="index === isIndex">
                <img src="@/assets/image/resumeLogin/勾选.png" alt="" />
              </div>
              <img :src="item.img" alt="" class="pdf-img" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import { upyunUrl } from '@/api/config'
import pefTop from '@/components/pefTop'

import preview1 from '@/views/resume/pdfExport/preview1'
import preview2 from '@/views/resume/pdfExport/preview2'
import preview3 from '@/views/resume/pdfExport/preview3'
import preview4 from '@/views/resume/pdfExport/preview4'
import preview5 from '@/views/resume/pdfExport/preview5'

export default {
  components: {
    pefTop,
    preview1,
    preview2,
    preview3,
    preview4,
    preview5
  },
  data() {
    return {
      // 用户当前选择的模板下标
      isIndex: 0,

      onlineResumeList: [], //在线简历数据
      resumeId: 0, // 当前简历id
      token: null, // 用户token
      type: null, // 小程序进来的类型 diagnose = 简历诊断、img = 简历预览图片、file = 下载简历
      resumeName: null, //简历名称
      upyunData: { policy: '', signature: '' }
    }
  },
  computed: {
    ...mapState(['currentResumeInfo'])
  },
  mounted() {
    window.scrollTo(0, 0)
  },
  created() {
    this.getOnlineResumeLisFn()
    if (this.$route.query.id) this.resumeId = this.$route.query.id
    if (this.$route.query.token) {
      this.token = this.$route.query.token
      sessionStorage.setItem('token', this.token)
      this.setToken(this.token)
    }
    if (this.$route.query.type) this.type = this.$route.query.type
    if (this.$route.query.isIndex) this.isIndex = parseInt(this.$route.query.isIndex)
    this.getResumeGenerateContentFn()
    this.getUpYunSignFn()
  },
  methods: {
    ...mapMutations({
      setToken: 'SET_TOKEN'
    }),
    ...mapActions([
      'getResumeGenerateContent',
      'resumeGenerateSave',
      'resumeFileUpyun',
      'getUpYunSign',
      'getOnlineResumeList'
    ]),
    // 获取在线模板简历列表
    async getOnlineResumeLisFn() {
      this.onlineResumeList = await this.getOnlineResumeList()
    },
    // 用户选择模板
    selectFn(item) {
      this.isIndex = item.isIndex
    },
    // 当用户选择导出
    printFn() {
      this.$refs[`preview${this.isIndex + 1}`].printFn()
    },
    async getResumeGenerateContentFn() {
      await this.getResumeGenerateContent({ id: this.resumeId || 0 })
      if (this.token) {
        if (this.type === 'diagnose' || this.type === 'file') {
          this.toGetPdf() // 生成小程序简历诊断文件流转网络路径 || 简历下载
        } else if (this.type === 'img') {
          this.scImg() // 生成小程序简历诊断预览图片
        }
      }
    },
    // 获取简历名字
    getName() {
      this.resumeName = this.currentResumeInfo.resume_name
      if (this.resumeName === null) {
        // 拼接导出文件名字
        this.resumeName = `${this.currentResumeInfo.name || 'xxx'}-${
          this.currentResumeInfo.intention.length
            ? this.currentResumeInfo.intention[0].intentionName
            : '岗位'
        }-${this.currentResumeInfo.num_work_experience || ''}年/${
          this.currentResumeInfo.education_experience.length
            ? this.currentResumeInfo.education_experience[0].end_time_year
            : ''
        }届毕业`
      }
    },
    // 获取文件流方法
    async toGetPdf(tile = '', download = false) {
      this.getName()
      // download：false为不下载
      this.getPdf(tile, download).then(res => {
        this.UploadPdf(res)
      })
    },
    // 上传pdf接口
    async UploadPdf(res) {
      // res拿到base64的pdf
      let pdfBase64Str = res
      let title = this.resumeName
      // 把简历名称存起来方便resumeFileUpyunFn接口取值
      sessionStorage.setItem('resume_title', title)
      let file = await this.dataURLtoFile(pdfBase64Str, title + '.pdf') // 调用一下下面的转文件流函数
      await this.resumeFileUpyunFn(file)
    },
    // 将base64转换为文件,接收2个参数，第一是base64，第二个是文件名字最后返回文件对象
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], filename, { type: mime })
    },
    // 上传简历
    async resumeFileUpyunFn(file) {
      const data = new FormData()
      data.append('file', file)
      data.append('policy', this.upyunData.policy)
      data.append('signature', this.upyunData.signature)
      const obj = await this.resumeFileUpyun(data)
      const oJson = {
        id: this.currentResumeInfo.id,
        resume_url: upyunUrl + obj.url,
        resume_name: this.resumeName,
        file_size: obj.file_size,
        all_result_n: null
      }
      this.resumeGenerateSaveFn(oJson)
    },
    // 获取又拍云签名
    async getUpYunSignFn() {
      this.upyunData = await this.getUpYunSign()
    },
    // 保存简历内容
    async resumeGenerateSaveFn(oJson) {
      await this.resumeGenerateSave(oJson)
      if (this.type === 'diagnose') {
        wx.miniProgram.redirectTo({
          url: `/pages/resumeSystem/diagnosis/uploadResume/index?id=${oJson.id}`
        })
      } else if (this.type === 'file') {
        wx.miniProgram.navigateBack()
      }
    },
    // 把简历模板一转换成图片
    async scImg() {
      const obj = await this.$refs[`preview${this.isIndex + 1}`].scImg()
      this.resumeGenerateSave({
        id: this.currentResumeInfo.id,
        resume_cover: obj.url,
        all_result_n: null
      }).then(() => {
        wx.miniProgram.redirectTo({
          url: `/pages/resumeSystem/previewResume/index?id=${this.currentResumeInfo.id}&isIndex=${this.isIndex}`
        })
      })
    }
  }
}
</script>

<style scoped lang="stylus">
.overflowHidden
  overflow hidden
.box
  background #f0f2f5
  min-height 100vh
  height auto
  .preview-Box
    display flex
    justify-content center
    margin-top 20px
    .preview-left
      width 828px
      height auto
      background #FFFFFF
      box-shadow 0 0 20px 0 rgba(221, 221, 221, 0.4)
      border-radius 10px
      margin-right 20px
    .preview-rigth
      overflow hidden
      overflow-y scroll
      width 350px
      height 1000px
      background #FFFFFF
      box-shadow 0 0 20px 0 rgba(221, 221, 221, 0.4)
      border-radius 10px
      padding 30px 48px 30px 52px
      box-sizing border-box
      .title
        text-align center
        font-size 20px
        font-family Source Han Sans CN
        font-weight 500
        color #333333
        margin-bottom 24px
      .preview-list
        .list-box
          width 251px
          height 355px
          background #FFFFFF
          box-shadow 0px 0px 20px 0px rgba(221, 221, 221, 0.86)
          border-radius 6px
          position relative
          margin-bottom 20px
          cursor pointer
          .box-baseimg
            position absolute
            bottom -2px
            right -1px
          img
            border-radius 6px
            width 100%
            height 100%
      .actvie
        border 1px solid #003DBB
    /* 滚动条样式 */
    .preview-rigth::-webkit-scrollbar /* 滚动条整体样式 */
      width 4px /* 高宽分别对应横竖滚动条的尺寸 */
      height 4px
    .preview-rigth::-webkit-scrollbar-thumb /* 滚动条里面小方块 */
      border-radius 10px
      -webkit-box-shadow inset 0 0 5px rgba(0, 0, 0, 0.3)
      background rgba(0, 0, 0, 0.2)
    .preview-rigth::-webkit-scrollbar-track /* 滚动条里面轨道 */
      -webkit-box-shadow inset 0 0 5px rgba(0, 0, 0, 0.2)
      border-radius 0
      background rgba(0, 0, 0, 0.1)
.web-view-loading
  position fixed
  left 0
  right 0
  top 0
  bottom 0
  width 100%
  height 100%
  z-index 999
  display flex
  justify-content center
  align-items center
  flex-direction column
  background-color #fff
  .el-icon-loading
    font-size 30px
    color #409eff
  .loading-text
    padding-top 10px
    font-size 14px
    color #409eff
</style>
