<template>
  <div class="body-box">
    <div class="wrapper" id="canvasImg">
      <!-- <el-button type="primary" class="deriveWord noprint" @click="printFn">一键导出</el-button> -->

      <!-- 头部 -->
      <div class="title-top">
        <div class="top-box">
          <div class="photograph">粘贴照片</div>
          <div class="box-rigth">
            <div class="rigth-message">
              <div class="message-name" v-if="obj && obj.name">
                {{ obj.name }}
              </div>
              <div class="message-position" v-if="obj && obj.intention && obj.intention.length">
                {{ obj.intention[0].category_label }}
              </div>
            </div>

            <!-- 求职意向 -->
            <div class="intention-centen">
              <div v-if="obj && obj.num_work_experience">
                {{ obj.num_work_experience }}年工作经验
                <span class="parting-line">|</span>
              </div>
              <div
                v-if="
                  obj &&
                  obj.intention &&
                  obj.intention.length &&
                  obj.intention[0].minwage_name !== '面议'
                "
              >
                期望薪资：{{ obj.intention[0].minwage_name }}-{{ obj.intention[0].maxwage_name }}
                <span class="parting-line">|</span>
              </div>
              <div
                v-if="
                  obj &&
                  obj.intention &&
                  obj.intention.length &&
                  obj.intention[0].minwage_name === '面议'
                "
              >
                期望薪资：{{ obj && obj.intention.length ? obj.intention[0].minwage_name : '' }}
                <span class="parting-line">|</span>
              </div>
              <div v-if="obj && obj.intention && obj.intention.length">
                工作地区：{{ obj.intention[0].cantonal }}
                <span class="parting-line">|</span>
              </div>
              <div v-if="obj && obj.intention && obj.intention.length">
                {{ obj.intention[0].nature_name }}
              </div>
            </div>
            <!-- 求职意向 -->

            <!-- 个人信息 -->
            <div class="personal-information">
              <div v-if="obj && obj.gender">性别：{{ obj.gender == 1 ? '男' : '女' }}</div>
              <div v-if="obj && obj.age">年龄：{{ obj.age }}</div>
              <div v-if="obj && obj.date_of_birth">出生年月：{{ obj.date_of_birth }}</div>
            </div>
            <!-- 个人信息 -->
          </div>
        </div>
      </div>
      <!-- 头部 -->

      <!-- 个人信息手机号 微信号 邮箱 -->
      <div class="cell-phone">
        <div class="phone-box" v-if="obj && obj.wechat">
          <div class="box-text">微信号</div>
          <div class="box-cell">{{ obj.wechat }}</div>
        </div>
        <div class="phone-box boder" v-if="obj && obj.phone_number">
          <div class="box-text">手机号</div>
          <div class="box-cell">{{ obj.phone_number }}</div>
        </div>
        <div class="phone-box" v-if="obj && obj.email">
          <div class="box-text">邮箱</div>
          <div class="box-cell">{{ obj.email }}</div>
        </div>
      </div>
      <!-- 个人信息手机号 微信号 邮箱 -->

      <!-- 最左边的竖线 最大的盒子 -->
      <div class="lefx-long">
        <!-- 标题区域 -->
        <div
          class="lihe question-table"
          v-if="obj && obj.education_experience && obj.education_experience.length"
        >
          <img src="@/assets//image//pdfExport//four/icon .png" alt="" />
          教育经历
        </div>
        <!-- 标题区域 -->
        <!-- 项目经历区域 -->
        <div v-if="obj && obj.education_experience && obj.education_experience.length">
          <div
            class="box question-table"
            v-for="(item, index) in obj.education_experience"
            :key="index"
          >
            <div class="dot"></div>
            <div class="time">{{ item.start_time_startDate }}-{{ item.start_time_endDate }}</div>
            <div class="company">{{ item.school_name }}</div>
            <div style="margin-left: auto">{{ item.major }}</div>
            <div class="position">{{ item.degree }}</div>
          </div>
        </div>
        <!-- 项目经历区域 -->

        <!-- 标题区域 -->
        <div class="lihe" v-if="obj && obj.work_experience && obj.work_experience.length">
          <img src="@/assets//image//pdfExport//four//icon.png" alt="" />
          工作经历
        </div>
        <!-- 标题区域 -->
        <!-- 工作经历区域 -->
        <div v-if="obj && obj.work_experience && obj.work_experience.length">
          <div class="box question-table" v-for="(item, index) in obj.work_experience" :key="index">
            <div class="dot"></div>
            <div class="time">{{ item.start_time_startDate }}-{{ item.start_time_endDate }}</div>
            <div class="company">
              <div>{{ item.company_name }}</div>
              <div
                class="company-listgzjl"
                v-for="(text, index) in item.work_experienceList"
                :key="index"
              >
                <div class="triangle"></div>
                <div>{{ text }}</div>
              </div>
            </div>
            <div class="position">{{ item.job_title }}</div>
          </div>
        </div>
        <!-- 工作经历区域 -->

        <!-- 标题区域 -->
        <div
          class="lihe question-table"
          v-if="obj && obj.project_experience && obj.project_experience.length"
        >
          <img src="@/assets//image//pdfExport//four/icon(3) .png" alt="" />
          项目经历
        </div>
        <!-- 标题区域 -->
        <!-- 项目经历区域 -->
        <div v-if="obj && obj.project_experience && obj.project_experience.length">
          <div
            class="box question-table"
            v-for="(item, index) in obj.project_experience"
            :key="index"
          >
            <div class="dot"></div>
            <div class="time">{{ item.start_time_startDate }}-{{ item.start_time_endDate }}</div>
            <div class="company">
              <div>{{ item.project_name }}</div>
              <div class="company-list">
                <div class="describe">经历描述：</div>
                <div class="jl">{{ item.description }}</div>
              </div>
            </div>
            <div class="position">{{ item.job_title }}</div>
          </div>
        </div>
        <!-- 项目经历区域 -->

        <!-- 标题区域 -->
        <div class="lihe question-table" v-if="obj && obj.skills_full && obj.skills_full.length">
          <img src="@/assets//image//pdfExport//four/icon (1).png" alt="" />
          相关技能
        </div>
        <!-- 标题区域 -->
        <!-- 商业技能区域 -->
        <div v-if="obj && obj.skills_full && obj.skills_full.length">
          <div class="box question-table" v-for="(item, index) in obj.skills_full" :key="index">
            <div class="dot"></div>
            <div class="time">{{ item.name }}</div>
            <div class="company">
              <div>掌握时长： {{ item.time }}个月</div>
              <div class="company-list">
                <div class="describe">技能描述：</div>
                <div class="jl">{{ item.description }}</div>
              </div>
            </div>
            <div class="position">{{ item.level }}</div>
          </div>
        </div>
        <!-- 商业技能区域 -->

        <!-- 标题区域 -->
        <div class="lihe question-table" v-if="obj && obj.awards_full && obj.awards_full.length">
          <img src="@/assets//image//pdfExport//four/icon (2).png" alt="" />
          荣誉奖项
        </div>
        <!-- 标题区域 -->
        <!-- 荣誉奖项区域 -->
        <div v-if="obj && obj.awards_full && obj.awards_full.length">
          <div class="box question-table" v-for="(item, index) in obj.awards_full" :key="index">
            <div class="dot"></div>
            <div class="time">{{ item.name }}</div>
            <div class="company">
              <div>获得时间：{{ item.time }}</div>
            </div>
          </div>
        </div>
        <!-- 荣誉奖项区域 -->

        <!-- 标题区域 -->
        <div class="lihe question-table" v-if="obj && obj.self_evaluation">
          <img src="@/assets//image//pdfExport//four/icon(1).png" alt="" />
          自我评价
        </div>
        <!-- 标题区域 -->
        <!-- 自我评价 -->
        <div class="self question-table" v-if="obj && obj.self_evaluation">
          {{ obj.self_evaluation }}
        </div>
        <!-- 自我评价 -->
      </div>
      <!-- 最左边的竖线 最大的盒子 -->
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import PdfLoader from '@/utils/htmlpdf.js'
import { scImg } from '@/utils/scImg'
export default {
  data() {
    return {
      obj: null
    }
  },
  computed: {
    ...mapState(['currentResumeInfo'])
  },
  created() {
    this.obj = this.currentResumeInfo
  },
  methods: {
    printFn() {
      let name = this.obj.resume_name
      if (name === null) {
        //拼接导出文件名字
        name = `${this.obj.name || 'xxx'}-${
          this.obj.intention.length ? this.obj.intention[0].intentionName : '岗位'
        }-${this.obj.num_work_experience || ''}年/${
          this.obj.education_experience.length ? this.obj.education_experience[0].end_time_year : ''
        }届毕业`
      }
      const pdf = document.getElementById('canvasImg') // 需要导出部分页面的id名
      this.pdfDownLoader = new PdfLoader(pdf, name, 'question-table') // fileName -->导出文件名,  question-table -->防止被截断的class名
      this.pdfDownLoader.outPutPdfFn(name)
    },
    // 页面转图方法
    async scImg() {
      const res = await scImg()
      return res
    }
  },
  watch: {
    currentResumeInfo(obj) {
      this.obj = obj
    }
  }
}
</script>

<style scoped lang="stylus">
.body-box
  background #f9f9f9
  border-radius 10px
.wrapper
  border-radius 10px
  max-width 828px
  background #fff
  padding 35px 41px 23px 32px
  box-sizing border-box
  .title-top
    width 100%
    height auto
    background #FEF0E6
    .top-box
      display flex
      .photograph
        width 117px
        height 150px
        line-height 150px
        background #FFF9F5
        border 1px solid #E77A2A
        font-size 13px
        font-family PingFang SC
        font-weight 400
        color #A3A6B5
        text-align center
        margin-right 34px
      .box-rigth
        flex 1
        padding-right 32px
      .rigth-message
        display flex
        margin-top 32px
        .message-name
          font-size 28px
          font-family PingFang SC
          font-weight bold
          color #333333
          margin-right 16px
        .message-position
          margin-top 14px
          font-size 16px
          font-family PingFang SC
          font-weight bold
          color #E77A2A
      .intention-centen
        display flex
        font-size 13px
        font-family PingFang SC
        font-weight 500
        color #333333
        margin-top 15px
        .parting-line
          margin 0 4px
          color #999
      .personal-information
        margin-top 10px
        font-size 13px
        font-family PingFang SC
        font-weight 500
        color #333333
        display flex
        justify-content space-between
        flex-wrap wrap
        line-height 25px
        div
          width 33.33%
  .cell-phone
    margin-top 20px
    border-top 1px solid #C6C3C1
    border-bottom 1px solid #C6C3C1
    padding 9px 38px
    box-sizing border-box
    display flex
    .phone-box
      display flex
      flex-direction column
      justify-content center
      align-items center
      width 33.33%
      .box-text
        font-size 13px
        font-family PingFang SC
        font-weight bold
        color #E77A2A
        line-height 26px
      .box-cell
        font-size 13px
        font-family PingFang SC
        font-weight bold
        color #333333
    .phone-box.boder
      border-left 1px solid #C6C3C1
      border-right 1px solid #C6C3C1
      margin 0 63px
      padding 0 62px
  .lefx-long
    margin-top 29px
    border-left 1px solid #C6C3C1
    height auto
    position relative
    .lihe
      border-bottom 1px solid #C6C3C1
      margin-left 27px
      font-size 19px
      font-family PingFang SC
      font-weight bold
      color #333333
      padding-bottom 4px
      margin-top 32px
      img
        width 25px
        height 25px
        position absolute
        left -13px
    .self
      margin-left 27px
      margin-top 18px
      font-size 13px
      font-family PingFang SC
      font-weight 500
      color #333333
      line-height 22px
    .box
      margin-left 27px
      display flex
      margin-top 19px
      font-size 15px
      font-family PingFang SC
      font-weight bold
      .dot
        width 10px
        height 10px
        background #E77A2A
        border-radius 50%
        position absolute
        margin-top 4px
        left -6px
      .time
        color #E77A2A
        width 160px
      .company
        color #333
        .company-list
          font-size 13px
          font-family PingFang SC
          font-weight 500
          color #333333
          line-height 27px
          width 314px
        .company-listgzjl
          font-size 13px
          font-family PingFang SC
          font-weight 500
          color #333333
          line-height 27px
          width 314px
          display flex
          .describe
            font-size 13px
            font-family PingFang SC
            font-weight bold
            color #333333
          .jl
            line-height 22px
          .triangle
            width 0
            height 0
            border-top 5px solid transparent
            border-bottom 5px solid transparent
            border-left 9px solid #E77A2A
            margin-right 7px
            margin-top 7px
      .position
        color #333
        margin-left auto
  .deriveWord
    position fixed
    top 50%
    right 0
</style>
